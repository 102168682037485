<template>
	<div>
		<a-tabs default-active-key="1">
			<a-tab-pane key="1" tab="Lejáró szerződések">
				<div class="tab-content-wrapper">
					<a-table
						:columns="columns1"
						:data-source="reminders"
						:row-key="(record) => record.rendszam"
						size="middle"
						:pagination="{ size: 'normal' }"
					>
						<div
							slot="filterDropdown"
							slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
							style="padding: 8px"
						>
							<a-input
								v-ant-ref="(c) => (searchInput = c)"
								:placeholder="`Keresés ${column.dataIndex}`"
								:value="selectedKeys[0]"
								style="width: 188px; margin-bottom: 8px; display: block;"
								@change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
								@pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
							/>
							<a-button
								type="primary"
								icon="search"
								size="small"
								style="width: 90px; margin-right: 8px"
								@click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
							>
								Keresés
							</a-button>
							<a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
								Visszaállítás
							</a-button>
						</div>
						<a-icon
							slot="filterIcon"
							slot-scope="filtered"
							type="search"
							:style="{ color: filtered ? '#108ee9' : undefined }"
						/>

						<template slot="customRender" slot-scope="text, record, index, column">
							<span v-if="searchText && searchedColumn === column.dataIndex">
								<template
									v-for="(fragment, i) in text
										.toString()
										.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
								>
									<mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{
										fragment
									}}</mark>
									<template v-else>{{ fragment }}</template>
								</template>
							</span>
							<template v-else>
								{{ text }}
							</template>
						</template>
						<span slot="icon"><a-icon type="car" theme="filled"/></span>
						<template slot="vehicleFullName" slot-scope="gyartmany, record">
							{{ gyartmany }} {{ record.modell }} {{ record.tipus }}
						</template>
					</a-table>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Közelgő események" force-render>
				<div class="tab-content-wrapper">
					<a-table
						:columns="columns2"
						:data-source="reminders"
						:row-key="(record) => record.rendszam"
						size="middle"
						:pagination="{ size: 'normal' }"
					>
						<div
							slot="filterDropdown"
							slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
							style="padding: 8px"
						>
							<a-input
								v-ant-ref="(c) => (searchInput = c)"
								:placeholder="`Keresés ${column.dataIndex}`"
								:value="selectedKeys[0]"
								style="width: 188px; margin-bottom: 8px; display: block;"
								@change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
								@pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
							/>
							<a-button
								type="primary"
								icon="search"
								size="small"
								style="width: 90px; margin-right: 8px"
								@click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
							>
								Keresés
							</a-button>
							<a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
								Visszaállítás
							</a-button>
						</div>
						<a-icon
							slot="filterIcon"
							slot-scope="filtered"
							type="search"
							:style="{ color: filtered ? '#108ee9' : undefined }"
						/>
						<template slot="customRender" slot-scope="text, record, index, column">
							<span v-if="searchText && searchedColumn === column.dataIndex">
								<template
									v-for="(fragment, i) in text
										.toString()
										.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
								>
									<mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{
										fragment
									}}</mark>
									<template v-else>{{ fragment }}</template>
								</template>
							</span>
							<template v-else>
								{{ text }}
							</template>
						</template>
						<span slot="icon"><a-icon type="car" theme="filled"/></span>
						<template slot="vehicleFullName" slot-scope="gyartmany, record">
							{{ gyartmany }} {{ record.modell }} {{ record.tipus }}
						</template>
						<span slot="eventName" slot-scope="text, record">
							<div v-if="record.rekordtipus == 'MATRICA'">
								<!-- {{ record.orszag }} {{ record.autokategoria }} {{ record.matricatipusnev }} -->
								Lejáró autópálya-matrica
							</div>
							<div v-if="record.rekordtipus == 'SZERVIZ'" style="display:inline;">{{ record.szerviztipusnev }}</div>
							&nbsp;

							<a-tooltip v-if="record.rekordtipus == 'SZERVIZ'" placement="top">
								<template slot="title">
									<span>
										{{ record.szerviznev }}<br />
										{{ record.szervizcim }}
									</span>
								</template>
								<a-icon type="environment" theme="filled" />
							</a-tooltip>
						</span>
						<span slot="eventDate" slot-scope="text, record">
							<div v-if="record.rekordtipus == 'MATRICA'">{{ record.ervvege }}</div>
							<div v-if="record.rekordtipus == 'SZERVIZ'">{{ record.kezdete }}</div>
							&nbsp;
						</span>
					</a-table>
				</div>
			</a-tab-pane>
			<a-tab-pane key="3" tab="Soron következő időszaki szervizek">
				<div class="tab-content-wrapper">
					<a-table
						:columns="columns3"
						:data-source="reminders"
						:row-key="(record) => record.rendszam"
						size="middle"
						:pagination="{ size: 'normal' }"
					>
						<div
							slot="filterDropdown"
							slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
							style="padding: 8px"
						>
							<a-input
								v-ant-ref="(c) => (searchInput = c)"
								:placeholder="`Keresés ${column.dataIndex}`"
								:value="selectedKeys[0]"
								style="width: 188px; margin-bottom: 8px; display: block;"
								@change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
								@pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
							/>
							<a-button
								type="primary"
								icon="search"
								size="small"
								style="width: 90px; margin-right: 8px"
								@click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
							>
								Keresés
							</a-button>
							<a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
								Visszaállítás
							</a-button>
						</div>
						<a-icon
							slot="filterIcon"
							slot-scope="filtered"
							type="search"
							:style="{ color: filtered ? '#108ee9' : undefined }"
						/>
						<template slot="customRender" slot-scope="text, record, index, column">
							<span v-if="searchText && searchedColumn === column.dataIndex">
								<template
									v-for="(fragment, i) in text
										.toString()
										.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
								>
									<mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{
										fragment
									}}</mark>
									<template v-else>{{ fragment }}</template>
								</template>
							</span>
							<template v-else>
								{{ text }}
							</template>
						</template>
						<span slot="icon"><a-icon type="car" theme="filled"/></span>

						<template slot="vehicleFullName" slot-scope="gyartmany, record">
							{{ gyartmany }} {{ record.modell }} {{ record.tipus }}
						</template>
						<span slot="customTitle"
							>Várható futásteljesítmény (km)

							<span class="tooltip">
								<a-icon type="info-circle" />
								<span class="tooltiptext"
									>A gépjármű futásteljesítmény adatai, valamint a gyártó által megadott szervizperiódus alapján
									meghatározzuk, hogy várhatóan mikor esedékes a gépjármű időszaki szervize. Ez a gépjármű használati
									módjától függően változhat, ezért kérjük, hogy a fedélzeti számítógép által jelzett időpontban vagy
									legkésőbb az általunk megadott hónap elején jelentkezzen be időszaki szervizre autópark-kezelő
									kollégánknál!</span
								>
							</span>
						</span>
						<template slot="varhatoidoszakiszervizkmName" slot-scope="varhatoidoszakiszervizkm, record">
							{{ record.varhatoidoszakiszervizkm | filterNumbers }}
						</template>
					</a-table>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import { mapState } from 'vuex';

function strComp(s1, s2) {
	var i;

	for (i = 0; i < s1.length && i < s2.length; i++) {
		if (s1[i] < s2[i]) {
			return -1;
		} else if (s1[i] > s2[i]) {
			return 1;
		}
	}
	if (s1.length < s2.length) {
		return -1;
	} else if (s1.length > s2.length) {
		return 1;
	}

	return 0;
}

const columns1 = [
	{
		title: '',
		dataIndex: 'rekordtipus',
		key: 'rekordtipus',
		defaultFilteredValue: ['SZERZODES'],
		scopedSlots: { customRender: 'icon' },
		filters: [
			{ text: 'Szerződés', value: 'SZERZODES' },
			{ text: 'Matrica', value: 'MATRICA' },
			{ text: 'Szerviz', value: 'SZERVIZ' },
		],
		onFilter: (value, record) => record.rekordtipus.includes(value),
	},
	{
		title: 'Rendszám',
		dataIndex: 'rendszam',
		rowKey: 'rendszam',
		scopedSlots: {
			filterDropdown: 'filterDropdown',
			filterIcon: 'filterIcon',
			customRender: 'customRender',
		},
		onFilter: (value, record) =>
			record.rendszam
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => {
					this.searchInput.focus();
				});
			}
		},
		sorter: (a, b) => strComp(a.rendszam, b.rendszam),
	},
	{
		title: 'Típus',
		dataIndex: 'gyartmany',
		key: 'gyartmany',
		scopedSlots: { customRender: 'vehicleFullName' },
		sorter: (a, b) => strComp(a.gyartmany, b.gyartmany),
	},
	{
		title: 'Szerződésszám',
		dataIndex: 'szerzodesszam',
		key: 'szerzodesszam',
		scopedSlots: {
			filterDropdown: 'filterDropdown',
			filterIcon: 'filterIcon',
			customRender: 'customRender',
		},
		onFilter: (value, record) =>
			record.szerzodesszam
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => {
					this.searchInput.focus();
				});
			}
		},
		sorter: (a, b) => strComp(a.szerzodesszam, b.szerzodesszam),
	},
	{
		title: 'Lejárat dátuma',
		dataIndex: 'futamidovege',
		key: 'futamidovege',
		defaultSortOrder: 'ascend',
		sorter: (a, b) => strComp(a.futamidovege, b.futamidovege),
	},
];
const columns2 = [
	{
		title: '',
		dataIndex: 'rekordtipus',
		key: 'rekordtipus',
		defaultFilteredValue: ['MATRICA', 'SZERVIZ'],
		scopedSlots: { customRender: 'icon' },
		filters: [
			{ text: 'Szerződés', value: 'SZERZODES' },
			{ text: 'Matrica', value: 'MATRICA' },
			{ text: 'Szerviz', value: 'SZERVIZ' },
		],
		onFilter: (value, record) => record.rekordtipus.includes(value),
	},
	{
		title: 'Esemény típusa',
		dataIndex: 'esemeny',
		key: 'esemeny',
		scopedSlots: { customRender: 'eventName' },
		sorter: (a, b) => strComp(a.esemeny, b.esemeny),
	},
	{
		title: 'Rendszám',
		dataIndex: 'rendszam',
		rowKey: 'rendszam',
		scopedSlots: {
			filterDropdown: 'filterDropdown',
			filterIcon: 'filterIcon',
			customRender: 'customRender',
		},
		onFilter: (value, record) =>
			record.rendszam
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => {
					this.searchInput.focus();
				});
			}
		},
		sorter: (a, b) => strComp(a.rendszam, b.rendszam),
	},
	{
		title: 'Típus',
		dataIndex: 'gyartmany',
		key: 'gyartmany',
		scopedSlots: { customRender: 'vehicleFullName' },
		sorter: (a, b) => strComp(a.gyartmany, b.gyartmany),
	},

	{
		title: 'Esemény napja',
		dataIndex: 'esedekesseg',
		key: 'esedekesseg',
		defaultSortOrder: 'ascend',
		scopedSlots: { customRender: 'eventDate' },
		sorter: (a, b) =>
			a.rekordtipus == 'MATRICA'
				? b.rekordtipus == 'MATRICA'
					? strComp(a.ervvege, b.ervvege)
					: strComp(a.ervvege, b.kezdete)
				: b.rekordtipus == 'MATRICA'
				? strComp(a.kezdete, b.ervvege)
				: strComp(a.kezdete, b.kezdete),
	},

	// {
	// 	title: 'Esedékesség dátuma',
	// 	dataIndex: 'esedekesseg',
	// 	key: 'esedekesseg',
	// 	defaultSortOrder: 'ascend',
	// 	scopedSlots: { customRender: 'eventDate' },
	// 	sorter: (a, b) =>
	// 		a.rekordtipus == 'MATRICA'
	// 			? b.rekordtipus == 'MATRICA'
	// 				? a.ervvege.localeCompare(b.ervvege)
	// 				: a.ervvege.localeCompare(b.kezdete)
	// 			: b.rekordtipus == 'MATRICA'
	// 			? a.kezdete.localeCompare(b.ervvege)
	// 			: a.kezdete.localeCompare(b.kezdete),
	// },
];
const columns3 = [
	{
		title: '',
		dataIndex: 'rekordtipus',
		key: 'rekordtipus',
		defaultFilteredValue: ['SZERZODES'],
		scopedSlots: { customRender: 'icon' },
		filters: [
			{ text: 'Szerződés', value: 'SZERZODES' },
			{ text: 'Matrica', value: 'MATRICA' },
			{ text: 'Szerviz', value: 'SZERVIZ' },
		],
		onFilter: (value, record) => record.rekordtipus.includes(value),
	},
	{
		title: 'Rendszám',
		dataIndex: 'rendszam',
		rowKey: 'rendszam',
		scopedSlots: {
			filterDropdown: 'filterDropdown',
			filterIcon: 'filterIcon',
			customRender: 'customRender',
		},
		onFilter: (value, record) =>
			record.rendszam
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => {
					this.searchInput.focus();
				});
			}
		},
		sorter: (a, b) => strComp(a.rendszam, b.rendszam),
	},
	{
		title: 'Típus',
		dataIndex: 'gyartmany',
		key: 'gyartmany',
		scopedSlots: { customRender: 'vehicleFullName' },
		sorter: (a, b) => strComp(a.gyartmany, b.gyartmany),
	},
	{
		dataIndex: 'varhatoidoszakiszervizkm',
		key: 'varhatoidoszakiszervizkm',
		slots: { title: 'customTitle' },

		scopedSlots: { customRender: 'varhatoidoszakiszervizkmName' },
		sorter: (a, b) => a.varhatoidoszakiszervizkm - b.varhatoidoszakiszervizkm,
	},
	{
		title: 'Várható esedékesség',
		dataIndex: 'varhatoidoszakiszervizhonap',
		key: 'varhatoidoszakiszervizhonap',
		sorter: (a, b) => strComp(a.varhatoidoszakiszervizhonap, b.varhatoidoszakiszervizhonap),
	},
];

export default {
	data() {
		return {
			searchText: '',
			searchInput: null,
			searchedColumn: '',
			columns1,
			columns2,
			columns3,
		};
	},

	created() {
		this.$store.dispatch('listReminders', {
			page: 0,
			//name: this.$route.name,
		});
	},

	computed: {
		...mapState({ reminders: (state) => state.auth.reminders }),
		// Other properties
	},

	methods: {
		handleSearch(selectedKeys, confirm, dataIndex) {
			confirm();
			this.searchText = selectedKeys[0];
			this.searchedColumn = dataIndex;
		},

		handleReset(clearFilters) {
			clearFilters();
			this.searchText = '';
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/_global.scss';
</style>

<style lang="scss" scoped>
.anticon-search {
	background: #6d8cb5!important;
	right: 25% !important;

	font-size: 20px !important;
}

/deep/ .ant-table-thead > tr > th .anticon-filter > svg,
/deep/ .ant-table-thead > tr > th .ant-table-filter-icon > svg {
	top: 46% !important;
}

/deep/ .ant-table-body {
	overflow: inherit;
}
.tooltip .tooltiptext {
	display: none;
	font-size: 0.8em;
	width: 45em;
	background-color: #324b50;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 2px;
	top: -15px;
	/* Position the tooltip */
	position: absolute;
	z-index: 100;
}

.tooltip:hover .tooltiptext {
	display: inline-block !important;
}
/deep/ .anticon-filter {
	display: none;
}

@media only screen and (max-width: 1700px) {
	.tooltip .tooltiptext {
		width: 35em;
	}
}

@media only screen and (max-width: 1550px) {
	.tooltip .tooltiptext {
		width: 30em;
	}
	.anticon-search {
		background: #6d8cb5!important;
		right: 20% !important;
	}
}

@media only screen and (max-width: 1350px) {
	.tooltip .tooltiptext {
		width: 25em;
	}
}

@media only screen and (max-width: 1200px) {
	.tooltip .tooltiptext {
		font-size: 0.75em;
		width: 25em;
	}
	.anticon-search {
		background: #6d8cb5!important;
		right: 15% !important;
	}
}

@media only screen and (max-width: 1100px) {
	.tooltip .tooltiptext {
		width: 20em;
	}
	.anticon-search {
		background: #6d8cb5!important;
		right: 10% !important;
	}
}

@media only screen and (max-width: 1000px) {
	.tooltip .tooltiptext {
		width: 20em;
	}
	.anticon-search {
		background: #6d8cb5!important;
		right: 0% !important;
	}
}

@media only screen and (max-width: 500px) {
	.tooltip .tooltiptext {
		width: 15em;
	}
	/deep/ .ant-table-body {
		overflow: scroll;
	}
}

@media (max-width: 576px) {
	/* Target devices narrower than 578px. */
	/deep/ .ant-tabs-tab-active,
	/deep/ .ant-tabs-tab {
		font-size: 0.7em !important;
	}

	/deep/ .ant-tabs-bar {
		padding: 0 0 !important;
	}
}
</style>
